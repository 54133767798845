import Hamburger from "hamburger-react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Links from "next/link";
import { useEffect, useState } from "react";
import en from "../../assets/images/menu/en.png";
import Logo from "../../assets/images/menu/logo.webp";
import vn from "../../assets/images/menu/vn.png";
import { LANGUAGES } from "../../constants/other";

const Menu = () => {
  const [isOpen, setOpen] = useState(false);
  const { t, i18n } = useTranslation(["translation"]);
  const [translate, setTranslate] = useState(false);
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    window.scrollY >= 10 ? setScroll(!0) : setScroll(!1);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <div
        className={
          scroll
            ? "bg-white shadow-[0px 5px 24px 0px rgba(0, 0, 0, 0.01) overflow-hidden 1lg:hidden h-24 transition-all duration-[.3s] ease-linear"
            : "bg-white shadow-[0px 5px 24px 0px rgba(0, 0, 0, 0.01) overflow-hidden 1lg:hidden h-24 transition-all duration-[.3s] ease-linear"
        }
      >
        <div
          className={
            scroll
              ? "flex justify-between items-center  h-24 fixed left-0 right-0 bg-white shadow-[0px_5px_24px_0px_rgba(0, 0, 0, 0.01) overflow-hidden 1lg:hidden transition-all duration-[.3s] ease-linear z-[999]"
              : "flex justify-between items-center  h-24 transition-all duration-[.3s] ease-linear z-[999]"
          }
        >
          <div className="flex justify-between items-center max-w-[1200px] w-full mx-auto 11xl:px-[2rem]">
            <div className="w-[11rem] h-[2.5rem] cursor-pointer">
              <Links href="/" prefetch={false} scroll>
                <Image src={Logo} alt="" loading="eager" />
              </Links>
            </div>
            <div className="flex items-center gap-4">
              <Links
                prefetch={false}
                href="/#production"
                scroll={true}
                className="text-link cursor-pointer"
              >
                <div
                  className="text-link cursor-pointer"
                  onClick={(e) => {
                    console.log(e);
                  }}
                >
                  {" "}
                  {t("Gói sản phẩm")}
                </div>
              </Links>
              <Links
                prefetch={false}
                href="/#solutions"
                scroll={true}
                className="text-link cursor-pointer"
              >
                <div className="text-link cursor-pointer">
                  {" "}
                  {t("Giải Pháp")}
                </div>
              </Links>
              <Links
                prefetch={false}
                href="/#pricing"
                scroll={true}
                className="text-link cursor-pointer"
              >
                <div className="text-link cursor-pointer"> {t("Bảng Giá")}</div>
              </Links>
              <Links
                href="https://docs-wasabi.qdtek.vn/"
                prefetch={false}
                className="text-link cursor-pointer"
              >
                <div className="text-link cursor-pointer">{t("FAQ")}</div>
              </Links>

              <Links
                href="/contact-us"
                prefetch={false}
                className="text-link cursor-pointer"
              >
                <div className="text-link cursor-pointer">{t("Liên hệ")}</div>
              </Links>
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (i18n.language === LANGUAGES.en) {
                    i18n.changeLanguage(LANGUAGES.vn);
                  } else if (i18n.language === LANGUAGES.vn) {
                    i18n.changeLanguage(LANGUAGES.en);
                  }
                  setTranslate(!translate);
                }}
              >
                {!translate ? (
                  <div className="h-[1rem]">
                    <Image src={vn} alt="Viet Nam" loading="eager" />
                  </div>
                ) : (
                  <div className="h-[1rem]">
                    <Image src={en} alt="" loading="eager" />
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Links href="/partners" prefetch={false}>
                <button className="text-but border z-[2] w-40 text-green overflow-hidden rounded-full	 after:transition-all after:opacity-0 after:duration-300 ease-in-out relative h-12 after:content after:bg-green after:left-[-70%] after:top-0 after:w-40 after:h-12 after:absolute after:rounded-full after:z-[-1] hover:after:left-0 hover:after:transition-all hover:after:duration-300 hover:after:opacity-100 hover:text-white hover:border-green">
                  {t("Trở thành đối tác")}
                </button>
              </Links>

              <Links
                href="/trial-register"
                prefetch={false}
                className="text-link cursor-pointer"
              >
                <button className="flex ce items-center flex-col bg-green text-but justify-center w-40 text-white rounded-full h-12 relative overflow-hidden after:content after:absolute after:top-0 after:left-[-100%] after:z-2 after:w-[100%] after:h-[100%] after:bg-hover1 after:delay-[5s after:duration-[1s] hover:after:left-[100%] ] 1sm:text-[14px] 1sm:w-[250px] z-[2] cursor-pointer">
                  <p className="text-link">{t("Dùng thử")}</p>
                  <p className="text-link">{t("1TB / 30 ngày")}</p>
                </button>
              </Links>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          scroll
            ? "menuMb bg-white shadow-[0_4px_19px_0px_rgba(0,0,0,0.01)]  hidden 1lg:block z-[99] h-[84px]"
            : "menuMb bg-white shadow-[0_4px_19px_0px_rgba(0,0,0,0.01)]  hidden 1lg:block z-[99]"
        }
      >
        <div
          className={
            scroll
              ? "1lg:fixed left-0 right-0 flex justify-between items-center max-w-[1200px] w-full mx-auto h-[84px]   11xl:px-[2rem] 1lg:shadow-[0_4px_19px_0px_rgba(0,0,0,0.09)] z-[999] bg-white transition-all duration-[.3s] ease-linear"
              : "flex justify-between items-center max-w-[1200px] w-full mx-auto h-[84px]  1lg:relative 11xl:px-[2rem] 1lg:shadow-[0_4px_19px_0px_rgba(0,0,0,0.09)] "
          }
        >
          <div className="flex items-center justify-between w-full">
            <div>
              <Links href="/" prefetch={false}>
                <div className="cursor-pointer 1lg:relative 1lg:z-[999] w-[8rem]  h-[2rem]">
                  <Image src={Logo} alt="" />
                </div>
              </Links>
            </div>

            <div className="flex items-center gap-6">
              <div
                className="cursor-pointer  h-[1rem] "
                onClick={() => {
                  if (i18n.language === LANGUAGES.en) {
                    i18n.changeLanguage(LANGUAGES.vn);
                  } else if (i18n.language === LANGUAGES.vn) {
                    i18n.changeLanguage(LANGUAGES.en);
                  }
                  setTranslate(!translate);
                }}
              >
                {!translate ? (
                  <div className="w-[2rem] z-[-1]">
                    <Image src={vn} alt="Viet Nam" />
                  </div>
                ) : (
                  <div className="w-[2rem] z-[-1]">
                    <Image src={en} alt="" />
                  </div>
                )}
              </div>
              {/* <Links href="/dang-nhap" prefetch={false}>
                <div className="w-[100%] cursor-pointer">
                  <Image src={pro} alt="" />
                </div>
              </Links> */}
              <div className="1lg:relative 1lg:z-[999]">
                <Hamburger toggled={isOpen} toggle={setOpen} />
              </div>
            </div>
          </div>
          <div
            className={
              !isOpen
                ? "flex items-center gap-4 1lg:absolute 1lg:top-[-500%] 1lg:flex-col 1lg:w-full 1lg:left-0 1lg:right-0 1lg:items-start 1lg:pt-[1rem] 11xl:px-[2rem] 1lg:bg-white transition-all ease-linear duration-200 delay-100 opacity-0  z-[999] shadow-[0_4px_17px_0px_rgba(0,0,0,0.09)]"
                : "flex items-center gap-4 1lg:absolute 1lg:top-[100%] 1lg:flex-col 1lg:w-full 1lg:left-0 1lg:right-0 1lg:items-start 1lg:pt-[1rem] 11xl:px-[2rem] 1lg:pb-[2rem] 1lg:bg-white transition-all ease-linear duration-200 delay-150 opacity-1 z-[999] shadow-[0_4px_17px_0px_rgba(0,0,0,0.09)]"
            }
          >
            <Links
              prefetch={false}
              href="/#production"
              scroll={true}
              className="text-link cursor-pointer"
            >
              <div
                className="text-link cursor-pointer"
                onClick={(e) => {
                  setOpen(!isOpen);
                }}
              >
                {" "}
                {t("Gói sản phẩm")}
              </div>
            </Links>

            <Links
              prefetch={false}
              href="/#solutions"
              scroll={true}
              className="text-link cursor-pointer"
            >
              <div
                className="text-link cursor-pointer"
                onClick={(e) => {
                  setOpen(!isOpen);
                }}
              >
                {" "}
                {t("Giải Pháp")}
              </div>
            </Links>
            <Links
              prefetch={false}
              href="/#pricing"
              scroll={true}
              className="text-link cursor-pointer"
            >
              <div
                className="text-link cursor-pointer"
                onClick={(e) => {
                  setOpen(!isOpen);
                }}
              >
                {" "}
                {t("Bảng Giá")}
              </div>
            </Links>
            <Links
              href="https://docs-wasabi.qdtek.vn/"
              prefetch={false}
              className="text-link cursor-pointer"
            >
              <div
                className="text-link cursor-pointer"
                onClick={(e) => {
                  setOpen(!isOpen);
                }}
              >
                {" "}
                {t("FAQ")}
              </div>
            </Links>

            <Links
              href="/contact-us"
              prefetch={false}
              className="text-link cursor-pointer"
            >
              <div
                className="text-link cursor-pointer"
                onClick={(e) => {
                  setOpen(!isOpen);
                }}
              >
                {" "}
                {t("Liên hệ")}
              </div>
            </Links>

            <div className="flex items-center gap-2 1lg:flex-col">
              <Links href="/partners" prefetch={false}>
                <button className="text-but border w-40 text-green rounded-full	h-12">
                  {t("Trở thành đối tác")}
                </button>
              </Links>
              <Links
                href="/trial-register"
                prefetch={false}
                className="text-link cursor-pointer"
              >
                <button className="flex ce items-center flex-col bg-green text-but justify-center w-40 text-white rounded-full h-12">
                  <p className="text-link"> {t("Dùng thử")}</p>
                  <p className="text-link"> {t("1TB / 30 ngày")}</p>
                </button>
              </Links>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
